:root {
  --main-color-green: #00998a;
  --main-color-green-half-dark: #009284;
  --main-color-green-dark: #004d45;
  --main-color-orange: #f7941d;
  --main-color-orange-dark: #d0770b;
  --main-bg-color-btn: #d9d9d9;
  --main-color-error: #ea4335;
}

.select {
  background-image: url(./components/Home/components/search/assets/icons/expand_arrow.svg);
  background-size: auto;
}

.msg-content::-webkit-scrollbar {
  width: 15px;
  background-color: #ddd;
}

.msg-content::-webkit-scrollbar-thumb {
  background-color: var(--main-color-green);
}

.msg-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--main-color-green-dark);
  cursor: pointer;
}

/* Ensure the marker icon always has opacity: 1 */
/* .leaflet-marker-icon {
  opacity: 1 !important; /* Force opacity to 1 
}

*/
/* Adjust popup styles if needed */
.leaflet-popup-content-wrapper {
  display: block !important;
  opacity: 1 !important;
  box-shadow: 0 0 0 !important;
  font-size: 14px;
  text-wrap: nowrap;
}
.leaflet-popup-content {
  position: absolute;
  background: white;
  display: block;
  padding: 5px;
  border-radius: 25px;
  right: -70%;
  top: -4px;
}
.leaflet-popup-content a {
  font-weight: bold;
  font-family: avenir-heavy;
  color: var(--main-color-green) !important;
}

.leaflet-popup .leaflet-zoom-animated {
  display: block !important;
  opacity: 1 !important;
  bottom: -7px;
  left: 0px;
}
.leaflet-popup-close-button {
  display: none;
}
.leaflet-popup-tip {
  display: none;
}
