@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Avenir-Arabic;
  src: url(../public/fonts/avenir-arabic-black.otf);
}
@font-face {
  font-family: Avenir-Arabic-heavy;
  src: url(../public/fonts/avenir-arabic-heavy.otf);
}
@font-face {
  font-family: Avenir-Arabic-book;
  src: url(../public/fonts/avenir-arabic-book.otf);
}
.avenir-black {
  font-family: Avenir-Arabic;
}

.avenir-heavy {
  font-family: Avenir-Arabic-heavy;
}
.avenir-book {
  font-family: Avenir-Arabic-book;
}
body {
  margin: 0;
  font-family: Avenir-Arabic;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Global Classes */
.container {
  @apply ps-4 pe-4 m-auto;
}
.m-inline-start {
  margin-inline-start: auto;
}
.text-webkit-center {
  text-align: -webkit-center;
}
.myHomeSwiper .swiper-button-prev,
.myHomeSwiper .swiper-button-next {
  color: #ffffff90 !important;
}
/* Change the color of inactive dots */
.myHomeSwiper .swiper-pagination-bullet {
  background: #fffffff0 !important;
}

/* Change the color of the active dot */
.swiper-pagination-bullet-active {
  /* background: red;  */
}


.hospital-profile .swiper-button-prev,
.hospital-profile .swiper-button-next {
  color: black !important;
}
.hospital-profile .swiper-slide {
  width: fit-content !important;
}
@media (min-width: 1024px) {
  .hospital-profile .swiper-button-prev,
  .hospital-profile .swiper-button-next {
    display: none !important;
  }
}
.hospital-profile .swiper-button-prev,
.hospital-profile .swiper-button-next {
  display: block !important;
}
@media (min-width: 700px) {
  .hospital-profile .swiper-slide {
    width: fit-content !important;
  }
}
@media (max-width: 650px) {
  .hospital-profile .swiper-wrapper {
    gap: 30px;
  }
}
.hospital-profile .swiper-wrapper {
  gap: 0px !important;
} 

.flex-box-center {
  @apply flex justify-center items-center;
}
/* Global Classes */

.flex-between-center {
  @apply flex justify-between items-center;
}

/* Global Classes */

.navbarend {
  @apply navbar-end flex justify-between w-full;
}
.navlinks::after {
  position: absolute;
  content: "";
  width: 0%;
  background: var(--main-color-orange);
  height: 3px;
  transform: translateX(-50%);
  left: 50%;
  top: 107%;
  border-radius: 10px;
  transition: 0.3s;
  margin: auto;
}
.navlinks:hover.navlinks::after {
  width: 100%;
}
.form-search .checkbox {
  --chkbg: black;
}
.form-search .checkbox {
  --chkfg: white;
}

/* Search */
.form-search {
  @apply bg-[--main-color-orange] pt-3 pb-8 px-12 rounded-md md:rounded-[98px] flex-between-center avenir-book text-center gap-5 flex-col md:flex-row;
}
.search-company {
  @apply py-3 px-4 my-8 mx-auto w-fit gap-5;
}
/* Search */
.box-majors-shadow {
  box-shadow: 1px -1px 8px #eee, -2px 1px 8px 1px #eee, 1px 1px 8px #eee;
}
.cards-majors {
  justify-content: space-evenly !important;
}
.box {
  transition: all 0.3s ease; /* Transition effect for all properties */
}
.box:hover {
  transform: rotate(5deg) scale(1.1);
}
.parent-cards-flex {
  @apply my-9 flex flex-row flex-wrap justify-center items-center;
}

.heading-cards-flex {
  @apply text-3xl text-black  lg:order-1 flex items-center justify-center lg:justify-start my-3 text-center lg:text-start;
  flex: 0 0 83%;
}
.browse-btn-more {
  @apply btn rounded-3xl text-white bg-[--main-color-green] avenir-heavy max-w-[120px] w-[100px] m-3 order-3 lg:order-1;
  flex: 0 0 34%;
}

.cards-flex {
  @apply my-4 flex-box-center gap-[2rem] w-full flex-wrap order-2 lg:order-1;
  flex: 0 0 100%;
}
.cards-hospital {
  @apply justify-center md:justify-start;
}
.heading-major-card {
  @apply min-w-[8rem] rounded-3xl text-white bg-[--main-color-green] p-2 avenir-heavy flex justify-center items-center gap-4 text-[15px] mx-4 opacity-0  transition-opacity duration-700 ease-in-out;
}
.cards-majors .card-major .heading-major-card {
  @apply absolute bottom-[-40%];
}

.card-major {
  @apply bg-white w-[190px] h-[185px] py-5 px-[20px] flex-box-center gap-5 flex-col box-majors-shadow rounded-[50%] transition-all duration-700 relative;
}
.cards-majors .card-major {
  @apply overflow-hidden;
}
.cards-majors .card-major::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: var(--main-color-green);
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.9s ease, transform 0.7s ease;
}

.card-major:hover::before {
  opacity: 1;
  transform: rotate(45deg) translate(100%, 100%);
}

.card-major:hover .heading-major-card {
  @apply flex opacity-100 h-fit left-0 bottom-0 relative;
}

.butn {
  @apply relative transition-all duration-700 z-10;
}

.butn:after {
  @apply absolute rounded-full;
  top: 1px;
  left: -2px;
  width: 103%;
  height: 98%;
  padding: 2px;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  content: "";
  box-shadow: 3px 3px 0 #f6c282ba;
  transition: opacity 0.7s, box-shadow 0.7s;
  box-sizing: content-box;
}

.butn:hover:after {
  opacity: 1;
}

/* Form Sign up */

.signup-hospitals .side-signup {
  @apply order-1 lg:order-2 flex-row lg:flex-col;
}
.signup-hospitals .side-signup .signs-bg-linear {
  height: auto !important;
}
.signup-hospitals .form-signup {
  @apply order-2 lg:order-1;
}
/* Form Sign up */
/*btn sign  */
.btn-signs {
  @apply btn mt-9 px-9 rounded-md bg-[--main-bg-color-btn] text-black hover:bg-opacity-5;
}
/*btn sign  */
/* Form Sign in */

.signs-bg-linear {
  background-image: linear-gradient(
    to bottom,
    var(--main-color-green) 0%,
    var(--main-color-green-half-dark) 26%,
    var(--main-color-green-dark) 85%
  );
}

.signs-bg-linear-choice:hover {
  background: linear-gradient(
    to top,
    var(--main-color-green) 0%,
    var(--main-color-green-dark) 100%
  );
}
.flex-half-grow {
  flex: 0 0 50%;
}

@media (min-width: 700px) {
  .flex-half-grow-choice {
    flex: 0 0 50%;
  }
}
@media (max-width: 767px) {
  .flex-half-grow-choice {
    flex: 0 !important;
  }
  .form-signup .side-option {
    height: 100% !important;
  }
}

.form-signs {
  @apply signs-bg-linear h-full w-full grid grid-cols-1 grid-rows-1 justify-center items-center text-center p-5 m-auto order-2 md:order-1 py-5 px-3;
}
.form-signup {
  /* padding-top: 250px !important; */
  /* @apply form-signs; */
  @apply  w-full grid grid-cols-1 grid-rows-1 justify-center items-center text-center p-5 m-auto order-2 md:order-1 py-5 px-3 min-h-[100vh];
  background-image: linear-gradient(
    to bottom,
    var(--main-color-green) 0%,
    var(--main-color-green-half-dark) 26%,
    var(--main-color-green-dark) 85%
  );
  /* margin: 0; */
  /* height: auto !important; */
  height: 100vh !important;
  /* height: calc(100vh-96px) !important; */
  /* overflow: scroll; */
}
.form-signup .side-options-parent {
  height: 100% !important;
}
.form-signup .side-options-parent .side-option {
  height: 50% !important;
}

.side-signup {
  height: auto !important;
}

.input-signin {
  @apply label-text text-white text-base py-1 m-auto tracking-wider;
}
.react-tel-input {
  height: 3rem;
}
.react-tel-input .form-control {
  width: 100%;
  height: 100% !important;
  padding-left: 67px !important;
}
.react-tel-input .flag-dropdown {
  padding: 5px !important;
}

/* Side options */
.side-options-parent {
  @apply flex flex-row md:flex-col flex-half-grow-choice order-1 md:order-2;
}
.side-option {
  @apply flex-box-center flex-col gap-2 p-6 flex-half-grow h-[192px] md:h-auto lg:h-fit lg:h-[calc(50vh-96px)] transition-all duration-[0.3s];
}

/* Side options */

/* Form Sign in */

/* Maps Search */
.search-tabs {
  @apply avenir-heavy text-black w-fit flex-between-center gap-4 mt-7 mb-4 mr-5;
}
.search-tab {
  @apply flex-between-center gap-3 p-3 rounded-3xl transition-all duration-700 hover:bg-[--main-color-orange-dark];
}
.link-search-tab {
  @apply flex-between-center gap-3;
}
.maps .search-tabs .maps-search {
  background: var(--main-color-orange-dark) !important;
}
.maps .search-tabs .hospital-search {
  background: var(--main-color-orange) !important;
}
.maps .search-tabs .hospital-search:hover {
  background: var(--main-color-orange-dark) !important;
}
/* Maps Search */

/* Hospitals */
.card-hospitals .swiper-wrapper {
  /* @apply flex-nowrap lg:flex-wrap gap-3 lg:gap-5; */
}
.cards-width {
  display: none !important;
}
@media (max-width: 1023px) and (min-width: 787px) {
  .cards-width {
    display: block;
  }
}
.btn-prev-next {
  @apply px-4 py-2 mx-1 bg-[--main-color-orange] text-white rounded-[40px] text-sm lg:text-[16px];
}

.btn-numbers {
  @apply h-[2.2rem] px-[13px] py-0 mx-1  text-black rounded-[50%] border;
}
/* Hospitals */

/* Hospital profile */
@media (min-width: 1135px) {
  .hospital .container {
    @apply mx-0;
  }
  .hospital-info {
    width: 100%;
  }
  .tabs-hospital a {
    @apply whitespace-pre;
  }
  .hospital-desc {
    @apply col-[1] row-[2];
  }
}
.hospital-description:has(.major-hospital) {
  @apply lg:row-[2/3];
}
@media (max-width: 1135px) {
  .hospital-desc {
    @apply row-[1];
  }
}
.major-hospital .swiper > div {
  /* @apply md:grid md:grid-cols-3 gap-6 p-3 w-[15rem] md:w-auto; */
}
/* Hospital profile */

/* Deal Info */
.another-deals .cards-flex {
  @apply md:col-[1/3] row-[1] md:justify-start;
}

.another-deals .add-panner {
  @apply md:col-[3] md:row-[1];
}
/* Deal Info */

/* Ads */
.add-panner {
  @apply bg-[--main-bg-color-btn] rounded-sm w-[300px] h-[500px] my-2;
}

/* Scrollbar */

/* Scrollbar */

/* Hospital Profile */
.hospital-profile .swiper-slide {
  /* width: 100% !important; */
}
.btn-edit-suggest {
  @apply btn bg-[--main-color-orange] transition-all duration-700 text-black avenir-heavy hover:bg-[--main-color-orange-dark] border-none;
}
/* Hospital Profile */
